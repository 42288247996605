import React, { useState } from "react";
import Sidebar from '../SideBar';
import axios from "axios";
import { useLocation } from 'react-router-dom';

const CreateKit = () => {
    const location = useLocation();
    const name = location.state; // Kit name or ID passed from the router state
    const [courseId, setCourseId] = useState(name);// Set the Kit ID (if needed)
    const [kit, setKit] = useState({
        name: "",
        description: "",
        category: "",
        price: "",
        image: "",
   
        overview: "",
        title: "",
        popupText: "",
        pdf: null
    });

    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState(false);

    const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

    // Handle input change for kit fields
    const handleKitChange = (e) => {
        const { name, value } = e.target;
        setKit((prevKit) => ({
            ...prevKit,
            [name]: value
        }));
    };

    // Handle file change for the main image
    const handleImageChange = (e) => {
        setKit({
            ...kit,
            image: e.target.files[0]
        });
    };



    // Handle file change for PDF
    const handlePdfChange = (e) => {
        setKit({
            ...kit,
            pdf: e.target.files[0]
        });
    };

    // Handle submit to add kit
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log("add")
        const formData = new FormData();
        formData.append('name', kit.name);
        formData.append('description', kit.description);
        formData.append('category', kit.category);
        formData.append('price', kit.price);
        formData.append('overview', kit.overview);
        formData.append('title', kit.title);
        formData.append('popupText', kit.popupText);

        if (kit.image) formData.append('img', kit.image);
     
        if (kit.pdf) formData.append('pdf', kit.pdf);
        console.log("add", formData)
        axiosInstance
            .post(`/courses/${courseId}/add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: "Bearer " + localStorage.getItem("auth_token"),
                },
            })
            .then((response) => {
                setToast(true);
                console.log("add")
                setLoading(false);
                setKit({
                    name: "",
                    description: "",
                    category: "",
                    price: "",
                    image: "",
           
                    overview: "",
                    title: "",
                    popupText: "",
                    pdf: null
                });
                console.log("Kit added successfully");
            })
            .catch((error) => {
                console.error("Error:", error);
                setLoading(false);
            });
    };

    return (
        <div>
            <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
                <div className="app-main">
                    <Sidebar />
                    <div className="col mt-4">
                        <div className="row">
                            <div className="page-title-actions px-3 d-flex">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                                        <li className="breadcrumb-item"><a href="/instructor">Instructor</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Create Kit</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="row" id="deleteTableItem">
                                <div className="col-md-12">
                                    <div className="main-card card d-flex h-100 flex-column">
                                        <div className="card-body">
                                            <h5 className="card-title py-2">Add New Kit</h5>
                                            <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                    <label className="form-label">Course ID</label>
                                                    <input
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        value={courseId}
                                                        onChange={(e) => setCourseId(e.target.value)}
                                                        placeholder="Enter Course ID"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Kit Name</label>
                                                    <input
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={kit.name}
                                                        onChange={handleKitChange}
                                                        placeholder="Enter Kit Name"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Description</label>
                                                    <textarea
                                                        required
                                                        className="form-control"
                                                        name="description"
                                                        value={kit.description}
                                                        onChange={handleKitChange}
                                                        placeholder="Enter Kit Description"
                                                    ></textarea>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Category</label>
                                                    <input
                                                        required
                                                        type="text"
                                                        className="form-control"
                                                        name="category"
                                                        value={kit.category}
                                                        onChange={handleKitChange}
                                                        placeholder="Enter Kit Category"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Price</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="price"
                                                        value={kit.price}
                                                        onChange={handleKitChange}
                                                        placeholder="Enter Kit Price"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Overview</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="overview"
                                                        value={kit.overview}
                                                        onChange={handleKitChange}
                                                        placeholder="Enter Kit Overview"
                                                    ></textarea>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="title"
                                                        value={kit.title}
                                                        onChange={handleKitChange}
                                                        placeholder="Enter Kit Title"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Popup Text</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="popupText"
                                                        value={kit.popupText}
                                                        onChange={handleKitChange}
                                                        placeholder="Enter Popup Text"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Main Image</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={handleImageChange}
                                                        accept="image/*"
                                                    />
                                                </div>
                                            
                                                <div className="mb-3">
                                                    <label className="form-label">PDF</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={handlePdfChange}
                                                        accept=".pdf"
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <button type="submit" className="btn bgBlue btn-dipBlue text-black">
                                                        Add Kit
                                                    </button>
                                                </div>
                                            </form>
                                            {loading && <p>Loading...</p>}
                                            {toast && <p>Kit added successfully!</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateKit;
