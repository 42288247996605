import React from "react";
import Navbar from './Navbar'
import Footer from './Footer'


const BatcheDetails = () => {
    const courses = [
        { id: 1, name: "Web Development", location: "Frontend & Backend, React" },
        { id: 2, name: "Data Science", location: "Python, Machine Learning" },
        { id: 3, name: "Robotics", location: "Hardware Integration, IoT" },
        { id: 4, name: "AI & Machine Learning", location: "Deep Learning, AI Models" },
      ];
    
      const projects = [
        { id: 1, name: "Build a Portfolio Website", tasks: 8 },
        { id: 2, name: "Create a Chatbot for Customer Support", tasks: 5 },
        { id: 3, name: "Develop a Robotics Car", tasks: 12 },
        { id: 4, name: "AI-powered Image Recognition Tool", tasks: 10 },
      ];

    return (
        <>
        <Navbar />
        <div class="container">
            <div class="row">
                <div className="col-lg-3 mt-4">

                    <div className="mb-4">
                      
                        <div className="input-group">
                            <input placeholder="Search for..." type="text" className="form-control" />
                            <div className="input-group-append">
                                <button className="btn btn-secondary">
                                    <i className="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Favorites */}
                    <div className="mb-4">
                        <div className="small mb-3">Favorites</div>
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <a href="#" className="nav-link active">
                                    <i className="fa fa-fw fa-line-chart mr-2"></i>Overview
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="fa fa-fw fa-calendar-o mr-2"></i>Calendar
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Projects */}
                    <div className="mb-4">
                        <div className="small mb-3">Projects</div>
                        <ul className="nav flex-column">
                            {projects.map((project) => (
                                <li key={project.id} className="nav-item">
                                    <a href="#" className="d-flex nav-link">
                                        <i className="fa fa-fw fa-star-o align-self-center mr-2"></i>
                                        {project.name}
                                        <span className="ml-auto align-self-center badge badge-secondary badge-pill">
                                            {project.tasks}
                                        </span>
                                    </a>
                                </li>
                            ))}
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="fa fa-fw fa-plus mr-2"></i>Add New Project
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Courses */}
                    <div className="mb-4">
                        <div className="small mb-3">Courses</div>
                        <ul className="nav flex-column">
                            {courses.map((course) => (
                                <li key={course.id} className="nav-item">
                                    <a href="#" className="d-flex nav-link">
                                        <div className="media">
                                            <div className="mr-3 align-self-center media-left media-middle">
                                                <div className="avatar-image avatar-image--loaded">
                                                    <div className="avatar avatar--md avatar-image__image">
                                                        <div className="avatar__content">
                                                            <img
                                                                src={`https://bootdey.com/img/Content/avatar/avatar${course.id}.png`}
                                                                alt={course.name} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div className="mt-0">{course.name}</div>
                                                <span className="small">{course.location}</span>
                                            </div>
                                        </div>
                                        <i className="fa fa-fw fa-circle text-success ml-auto align-self-center ml-2"></i>
                                    </a>
                                </li>
                            ))}
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="fa fa-fw fa-plus mr-2"></i>Add New Course
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-9 mt-4">
                
                    <div class="mb-3 Card_custom-card--border_5wJKy card">
                        <div class="table-responsive-xl m-4">
                            <table class="mb-0 table table-hover">
                                <thead>
                                    <tr>
                                        <th class="align-middle bt-0">Star</th>
                                        <th class="align-middle bt-0">Courses</th>
                                        <th class="align-middle bt-0">Status</th>
                                        <th class="align-middle bt-0">Enrolled Students</th>
                                        <th class="align-middle bt-0">People</th>
                                        <th class="align-middle bt-0 text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">
                                            <div class="text-inverse">
                                                <a href="#"><i class="fa fa-fw fa-lg fa-star-o"></i></a>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            <div><a href="#">AI Robotics Kit for Beginners</a></div><span>Last Edited by: Savanah Durgan <br />Sunday, 12 June, 2024</span>
                                        </td>
                                        <td class="align-middle">
                                            <span class="badge badge-success badge-pill">Available</span>
                                        </td>
                                        <td class="align-middle">
                                            <div class="mb-2 progress" style={{ height: "5px" }}>
                                                <div class="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: "25%" }}></div>
                                            </div>
                                            <div>Batch Time: <span class="text-inverse">Monday, 15 January 2025, 10:00 AM</span></div>
                                            <div>Enrolled Students: <span class="text-inverse">36/94</span></div>
                                        </td>
                                        <td class="align-middle">
                                            <div class="avatar-image avatar-image--loaded">
                                                <div class="avatar avatar--md avatar-image__image">
                                                    <div class="avatar__content"><p className="text-center">Filled</p></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="align-middle text-right">
                                            <div class="btn-group">
                                                <button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle btn btn-link"><i class="fa fa-gear"></i></button>
                                                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-folder-open mr-2"></i>View</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-ticket mr-2"></i>Add Task</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-paperclip mr-2"></i>Add Files</button>
                                                    <div tabindex="-1" class="dropdown-divider"></div>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-trash mr-2"></i>Delete</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            <div class="text-inverse">
                                                <a href="#"><i class="fa fa-fw fa-lg fa-star-o"></i></a>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            <div><a href="#">Advanced Robotics for Teens</a></div><span>Last Edited by: Brando Gutkowski <br />Friday, 12 December, 2024</span>
                                        </td>
                                        <td class="align-middle">
                                            <span class="badge badge-warning badge-pill">Limited Slots</span>
                                        </td>
                                        <td class="align-middle">
                                            <div class="mb-2 progress" style={{ height: "5px" }}>
                                                <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: "50%" }}></div>
                                            </div>
                                            <div>Batch Time: <span class="text-inverse">Wednesday, 18 January 2025, 1:00 PM</span></div>
                                            <div>Enrolled Students: <span class="text-inverse">48/94</span></div>
                                        </td>
                                        <td class="align-middle">
                                            <div class="avatar-image avatar-image--loaded">
                                                <div class="avatar avatar--md avatar-image__image">
                                                    <div class="avatar__content"><p className="text-center">50</p></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="align-middle text-right">
                                            <div class="btn-group">
                                                <button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle btn btn-link"><i class="fa fa-gear"></i></button>
                                                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-folder-open mr-2"></i>View</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-ticket mr-2"></i>Add Task</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-paperclip mr-2"></i>Add Files</button>
                                                    <div tabindex="-1" class="dropdown-divider"></div>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-trash mr-2"></i>Delete</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            <div class="text-inverse">
                                                <a href="#"><i class="fa fa-fw fa-lg fa-star-o"></i></a>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            <div><a href="#">AI Programming for Kids</a></div><span>Last Edited by: Wilmer Gorczany <br />Tuesday, 12 June, 2024</span>
                                        </td>
                                        <td class="align-middle">
                                            <span class="badge badge-success badge-pill">Active</span>
                                        </td>
                                        <td class="align-middle">
                                            <div class="mb-2 progress" style={{ height: "5px" }}>
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: "60%" }}></div>
                                            </div>
                                            <div>Batch Time: <span class="text-inverse">Friday, 14 January 2025, 9:00 AM</span></div>
                                            <div>Enrolled Students: <span class="text-inverse">65/94</span></div>
                                        </td>
                                        <td class="align-middle">
                                            <div class="avatar-image avatar-image--loaded">
                                                <div class="avatar avatar--md avatar-image__image">
                                                    <div class="avatar__content">
                                                    <div class="avatar__content"><p className="text-center">50</p></div>   </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="align-middle text-right">
                                            <div class="btn-group">
                                                <button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle btn btn-link"><i class="fa fa-gear"></i></button>
                                                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-folder-open mr-2"></i>View</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-ticket mr-2"></i>Add Task</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-paperclip mr-2"></i>Add Files</button>
                                                    <div tabindex="-1" class="dropdown-divider"></div>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-trash mr-2"></i>Delete</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">
                                            <div class="text-inverse">
                                                <a href="#"><i class="fa fa-fw fa-lg fa-star-o"></i></a>
                                            </div>
                                        </td>
                                        <td class="align-middle">
                                            <div><a href="#">AI Robotics Kit</a></div><span>Last Edited by: John Doe <br />Monday, 10 December, 2024</span>
                                        </td>
                                        <td class="align-middle">
                                            <span class="badge badge-success badge-pill">Available</span>
                                        </td>
                                        <td class="align-middle">
                                            <div class="mb-2 progress" style={{ height: "5px" }}>
                                                <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: "60%" }}></div>
                                            </div>
                                            <div>Batch Time: <span class="text-inverse">Tuesday, 16 January 2025, 2:00 PM</span></div>
                                            <div>Enrolled Students: <span class="text-inverse">12/20</span></div>
                                        </td>
                                        <td class="align-middle">
                                            <div class="avatar-image avatar-image--loaded">
                                                <div class="avatar avatar--md avatar-image__image">
                                                    <div class="avatar__content">
                                                    <div class="avatar__content"><p className="text-center">50</p></div>    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="align-middle text-right">
                                            <div class="btn-group">
                                                <button type="button" aria-haspopup="true" aria-expanded="false" class="dropdown-toggle btn btn-link"><i class="fa fa-gear"></i></button>
                                                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-folder-open mr-2"></i>View Details</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-calendar mr-2"></i>View Schedule</button>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-paperclip mr-2"></i>Add Files</button>
                                                    <div tabindex="-1" class="dropdown-divider"></div>
                                                    <button type="button" tabindex="0" class="dropdown-item"><i class="fa fa-fw fa-trash mr-2"></i>Delete</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        </>

);
};

export default BatcheDetails;