import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from "./Navbar";
import Footer from "./Footer";

const ViewKits = () => {
  const { courseId } = useParams();
  const [kits, setKits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const fetchKits = async () => {
      try {
        const response = await axiosInstance.get(`/courses/${courseId}/kits`);
        setKits(response.data.kits);
      } catch (err) {
        console.error('Error fetching kits:', err);
        setError('Failed to load kits.');
      } finally {
        setLoading(false);
      }
    };

    fetchKits();
  }, [courseId]);

  if (loading) return <p className="text-center">Loading kits...</p>;
  if (error) return <p className="text-center text-danger">{error}</p>;

  return (
    <div>
      <Navbar />
   
        <h1 className="text-center mb-4 mt-4">Available Kits with this Course</h1>
        {kits.length === 0 ? (
          <p className="text-center">No kits available for this course.</p>
        ) : (
          <section class="course-section ">
          <div class="course-warp container">
          <div className="row course-items-area ">
            {kits.map((kit) => (
              <div className="col-md-4 mb-4 d-flex align-items-stretch" key={kit._id}>
                <div className="card shadow-sm h-100">
                <div className="course-item">
        <div
          className="course-thumb set-bg"
          style={{ backgroundImage: `url(${kit.image})` }}
          aria-label="Course thumbnail"
        >
          <div className="price text-white">Price: ₹{kit.price}

          </div>
        </div>
                  
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title">{kit.name}</h5>
                    <p className="card-text">{kit.description}</p>
                 
                   <p></p>
                    {kit.overview && (
                      <div className="mb-3">
                    
                        <p>    <strong>Overview:</strong> {kit.overview}</p>
                      </div>
                    )}
                    {kit.popupText && (
                      <div className="alert alert-info p-2 text-center" role="alert">
                        <strong>Quick Info:</strong> {kit.popupText}
                      </div>
                    )}
                    {kit.additionalImages && kit.additionalImages.length > 0 && (
                      <div className="mb-3">
                        <strong>Gallery:</strong>
                        <div className="d-flex overflow-auto mt-2">
                          {kit.additionalImages.map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`Additional ${kit.name} ${index + 1}`}
                              style={{ width: '50px', height: '50px', marginRight: '10px', objectFit: 'cover' }}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    <a
                      href={kit.pdf}
                      className="btn btn-primary mt-auto"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View PDF Guide
                    </a>
                  </div>
                  </div>

                </div>
              </div>
            ))}
            </div>
            </div>
         </section>
        
        )}
   
      <Footer />
    </div>
  );
};

export default ViewKits;
