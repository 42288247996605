import React, { useEffect, useState } from "react";
import Sidebar from '../SideBar';
import { useDispatch } from "react-redux";
import { fetchAllCourseInfo } from "../../../redux/course/courseAction";
import axios from "axios";
import { ChakraProvider, useToast } from "@chakra-ui/react";

const CreateCourse = () => {
  const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [courseThumbnail, setCourseThumbnail] = useState("");
  const [courseDescription, setCourseDescription] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseLink, setCourseLink] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [coursePdf, setCoursePdf] = useState("");
  const [type, setType] = useState("");
  const [pdfLabel, setPdfLabel] = useState("Choose PDF");
  const [imgLabel, setImgLabel] = useState("Choose photo");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [userData, setUserData] = useState(null);

  // State for details schema
  const [details, setDetails] = useState({
    title: "",
    overview: "",
    classSchedule: "",
    classes: [],
    cards: [],
  });
  

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('user');
    if (userDataFromStorage) {
      try {
        const parsedData = JSON.parse(userDataFromStorage);
        setUserData(parsedData);
      } catch (error) {
        console.error('Failed to parse user data:', error);
      }
    }
  }, []);

  const teacherName = userData?.userName;

  const [kits, setKits] = useState([
    { name: "", description: "", category: "", price: "", image: "", pdf: "" },
  ]);
  const [showKitButton, setShowKitButton] = useState(true); // To toggle the "Add Kit" button

  // Handle change for kit fields
  const handleKitChange = (index, e) => {
    const { name, value } = e.target;
    const updatedKits = [...kits];
    updatedKits[index][name] = value;
    setKits(updatedKits);
  };

  // Handle file change for kit's image and pdf
  const handleKitFileChange = (index, e) => {
    const { name, files } = e.target;
    const updatedKits = [...kits];
    updatedKits[index][name] = files[0]; // Assuming one file is uploaded per field
    setKits(updatedKits);
  };

  // Add a new kit
  const handleAddKit = () => {
    setKits([
      ...kits,
      { name: "", description: "", category: "", price: "", image: "", pdf: "" },
    ]);
  };

  // Remove a kit
  const handleRemoveKit = (index) => {
    const updatedKits = kits.filter((_, i) => i !== index);
    setKits(updatedKits);
  };


  const handleDetailsChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

 // Handle change for a specific field in a class object
const handleClassChange = (index, e) => {
  const { name, value } = e.target;
  const updatedClasses = [...details.classes];
  updatedClasses[index] = { ...updatedClasses[index], [name]: value }; // Update the specific field
  setDetails({ ...details, classes: updatedClasses });
};

// Handle change for the topics field in a class object
const handleTopicsChange = (index, e) => {
  const updatedClasses = [...details.classes];
  updatedClasses[index] = {
    ...updatedClasses[index],
    topics: e.target.value.split("\n"), // Split topics by new lines
  };
  setDetails({ ...details, classes: updatedClasses });
};

// Add a new empty class object
const handleAddClass = () => {
  setDetails({
    ...details,
    classes: [
      ...details.classes,
      {
        title: "",
        objective: "",
        topics: [],
        activity: "",
        code: "",
      },
    ],
  });
};

// Remove a class object from the list
const handleRemoveClass = (index) => {
  const updatedClasses = details.classes.filter((_, i) => i !== index);
  setDetails({ ...details, classes: updatedClasses });
};


// Handle change for a specific class name in classSchedule
const handleScheduleChange = (e) => {
  const updatedSchedule = e.target.value; // Update the classSchedule string directly
  setDetails({ ...details, classSchedule: updatedSchedule });
};


  const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL});
  const dispatch = useDispatch();

  const courseFormHandler = (e) => {
    e.preventDefault();
    setLoading(true);
  
    const formData = new FormData();
    formData.append("courseName", courseName);
    formData.append("courseDescription", courseDescription);
    formData.append("type", type);
    formData.append("img", courseThumbnail);
    formData.append("courseLink", courseLink);
    formData.append("coursePrice", coursePrice);
    formData.append("pdf", coursePdf);
    formData.append("teacherName", teacherName);  // Add teacherName here
  
    // Append kits data
    const updatedKits = kits.map((kit, index) => {
      // Append the image and PDF files for each kit
      formData.append(`kitImage-${index}`, kit.image); // Append image file to formData
      formData.append(`kitPdf-${index}`, kit.pdf); // Append PDF file to formData
      return { ...kit, image: undefined, pdf: undefined }; // Remove file references before converting to JSON
    });
  
    formData.append("kits", JSON.stringify(updatedKits)); // Append kits data as JSON
  
    // Append details schema data
    formData.append("details", JSON.stringify(details));
  
    axiosInstance
      .post("/post-course", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("auth_token"),
        },
      })
      .then((response) => {
        setLoading(false);
        toast({
          title: "Course created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Reset form
        setCourseDescription("");
        setCourseName("");
        setCourseThumbnail("");
        setCourseLink("");
        setCoursePrice("");
        setCoursePdf("");
        setType("");
        setPdfLabel("Choose PDF");
        setKits([
          {
            name: "",
            description: "",
            category: "",
            price: "",
            image: "",
            pdf: "",
          }
        ]); // Reset kits to an empty object
        setDetails({
          title: "",
          text: "",
          features: "",
          overview: "",
          cards: [{ icon: "", heading: "", description: "" }],
          classSchedule: "", // Reset to an array with one empty string
          classes: [
            {
              title: "",
              objective: "",
              topics: [],
              activity: "",
              code: "",
            },
          ],
        });
        dispatch(fetchAllCourseInfo());
        dispatch({
          type: "GET__COURSES",
          payload: true,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  



  return (
    <div>
        <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
          <div className="app-main">
            <Sidebar />
            <div className="col mt-4">
            <div className="row">
                <div className="page-title-actions px-3 d-flex">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><a href="">Dashboard</a></li>
                      <li className="breadcrumb-item"><a href="">Create Course</a></li>
                      <li className="breadcrumb-item active" aria-current="page">Create</li>
                    </ol>
                  </nav>
                </div>

                <div className="row" id="deleteTableItem">
                  <div className="col-md-12">
                    <div className="main-card card d-flex h-100 flex-column">
                      <div className="card-body">
                        <h5 className="card-title py-2">New Course</h5>
                        <form onSubmit={courseFormHandler} encType="multipart/form-data">
                          <input type="hidden" name="_token" value="zApQm200TRCSwlgCvq8JHVIYRC6flSbhaWtzbvCd" autoComplete="off"/>
                          <div className="row">
                          <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Course Name</label>
                                <input
                                  onChange={(e) => setCourseName(e.target.value)}
                                  value={courseName}
                                  type="text"
                                  placeholder="Enter course name"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Course Description</label>
                                <input
                                  onChange={(e) => setCourseDescription(e.target.value)}
                                  value={courseDescription}
                                  className="form-control"
                                  placeholder="Enter course Description"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Course Thumbnail</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  multiple 
                                  filename="img"
                                  onChange={(e) => {
                                    console.log(e.target.files[0]);
                                    setCourseThumbnail(e.target.files[0]);
                                    setImgLabel(`${e.target.files.length} file(s) selected`);
                                  }}
                                  id="custom-file"
                                  custom
                                  label={imgLabel ? `${imgLabel}` : "Choose photo"}
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Course Link</label>
                                <input
                                  onChange={(e) => setCourseLink(e.target.value)}
                                  value={courseLink}
                                  as="textarea"
                                  rows={1}
                                  placeholder="Enter course link"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Course Price</label>
                                <input
                                  onChange={(e) => setCoursePrice(e.target.value)}
                                  value={coursePrice}
                                  as="textarea"
                                  rows={1}
                                  placeholder="Enter course price"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Course Type</label>
                                <input
                                  onChange={(e) => setType(e.target.value)}
                                  value={type}
                                  as="textarea"
                                  rows={1}
                                  placeholder="Enter course price"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="mb-3">
                                <label className="form-label">Course PDF (.pdf)</label>
                                <div className="input-group">
                                  <input
                                    type="file"
                                    filename="pdf"
                                    className="form-control"
                                    multiple
                                    onChange={(e) => {
                                      console.log(e.target.files[0]);
                                      setCoursePdf(e.target.files[0]);
                                      setPdfLabel(`${e.target.files.length} file(s) selected`);
                                    }}
                                    id="custom-file-pdf"
                                    custom
                                    label={pdfLabel ? `${pdfLabel}` : "Choose PDF"}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Course Form</h3>
        {showKitButton && (
          <button type="button" className="btn btn-primary" onClick={handleAddKit}>
            Add Kit
          </button>
        )}
      </div>

      {kits.map((kit, index) => (
        <div key={index}>
          <h4>Kit {index + 1}</h4>
          <div className="mb-3">
            <label className="form-label">Kit Name</label>
            <input
              type="text"
              className="form-control"
              value={kit.name}
              onChange={(e) => handleKitChange(index, e)}
              name="name"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              value={kit.description}
              onChange={(e) => handleKitChange(index, e)}
              name="description"
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">Category</label>
            <input
              type="text"
              className="form-control"
              value={kit.category}
              onChange={(e) => handleKitChange(index, e)}
              name="category"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Price</label>
            <input
              type="number"
              className="form-control"
              value={kit.price}
              onChange={(e) => handleKitChange(index, e)}
              name="price"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Kit Image</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => handleKitFileChange(index, e)}
              name="image"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Kit PDF</label>
            <input
              type="file"
              className="form-control"
              onChange={(e) => handleKitFileChange(index, e)}
              name="pdf"
            />
          </div>
          <div className="float-right">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-danger btn-sm ml-2"
                onClick={() => handleRemoveKit(index)}
              >
                Remove Kit
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>

                            {/* New fields for details schema */}
                            <div className="col-12">
  <h5 className="mt-3">Course Details</h5>
  <div className="mb-3">
    <label className="form-label">Details Title</label>
    <input
      type="text"
      className="form-control"
      value={details.title}
      name="title"
      onChange={handleDetailsChange}
    />
  </div>
  <div className="mb-3">
    <label className="form-label">Overview</label>
    <textarea
      className="form-control"
      value={details.overview}
      name="overview"
      onChange={handleDetailsChange}
    ></textarea>
  </div>
  
  <h6>Class Schedule</h6>
<div className="d-flex align-items-center mb-2">
<textarea
    type="text"
    className="form-control me-2"
    value={details.classSchedule}
    name="classSchedule"
    onChange={handleScheduleChange} // Handle change for the single string
    ></textarea>
</div>

  <h6>Classes</h6>
  {details.classes.map((cls, index) => (
    <div key={index} className="card my-2 p-2">
      <div className="mb-2">
        <label className="form-label">Class Title</label>
        <input
          type="text"
          className="form-control"
          value={cls.title}
          name="title"
          onChange={(e) => handleClassChange(index, e)}
        />
      </div>
      <div className="mb-2">
        <label className="form-label">Objective</label>
        <textarea
          className="form-control"
          value={cls.objective}
          name="objective"
          onChange={(e) => handleClassChange(index, e)}
        ></textarea>
      </div>
      <div className="mb-2">
        <label className="form-label">Topics</label>
        <textarea
          className="form-control"
          value={cls.topics.join('\n')}
          name="topics"
          onChange={(e) => handleTopicsChange(index, e)}
        ></textarea>
        <small className="text-muted">Enter topics separated by new lines.</small>
      </div>
      <div className="mb-2">
        <label className="form-label">Activity</label>
        <textarea
          className="form-control"
          value={cls.activity}
          name="activity"
          onChange={(e) => handleClassChange(index, e)}
        ></textarea>
      </div>
      <div className="mb-2">
        <label className="form-label">Code</label>
        <textarea
          className="form-control"
          value={cls.code}
          name="code"
          onChange={(e) => handleClassChange(index, e)}
        ></textarea>
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-danger btn-sm"
          onClick={() => handleRemoveClass(index)}
        >
          Remove Class
        </button>
      </div>
    </div>
  ))}
  <button
    type="button"
    className="btn btn-primary mt-3"
    onClick={handleAddClass}
  >
    Add Class
  </button>

</div>

                          </div>
                          {/* Submit Button */}
                          <button type="submit" className="btn btn-primary mt-4">
                            Create Course
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CreateCourse;
