import React, { useState } from "react";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Footer";

const TeacherEnrollForm = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-4">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-5 text-center p-0 mt-3 mb-2">
            <div className="card px-4 pt-4 pb-0 mt-3 mb-3">
              <h2 id="heading">Join Our Virtual School as a Teacher</h2>
              <p>Fill in all the fields to complete your enrollment process.</p>

              <form id="msform">
                {/* Progressbar */}
                <ul id="progressbar">
                  <li className={step >= 1 ? "active" : ""} id="account">
                    <strong>Account</strong>
                  </li>
                  <li className={step >= 2 ? "active" : ""} id="personal">
                    <strong>Personal Info</strong>
                  </li>
                  <li className={step >= 3 ? "active" : ""} id="personal">
                    <strong>Qualifications</strong>
                  </li>
                  <li className={step >= 4 ? "active" : ""} id="personal">
                    <strong>Experience</strong>
                  </li>
                  <li className={step === 5 ? "active" : ""} id="confirm">
                    <strong>Finish</strong>
                  </li>
                </ul>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: `${(step / 5) * 100}%` }}
                  ></div>
                </div>
                <br />

                {/* Step 1 - Account Information */}
                {step === 1 && (
                  <fieldset>
                    <div className="form-card">
                      <h2 className="fs-title">Account Information:</h2>
                      <label className="fieldlabels">Email: *</label>
                      <input type="email" name="email" placeholder="Email Id" required />
                      <label className="fieldlabels">Username: *</label>
                      <input type="text" name="uname" placeholder="Username" required />
                      <label className="fieldlabels">Password: *</label>
                      <input type="password" name="pwd" placeholder="Password" required />
                      <label className="fieldlabels">Confirm Password: *</label>
                      <input type="password" name="cpwd" placeholder="Confirm Password" required />
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={nextStep}
                    />
                  </fieldset>
                )}

                {/* Step 2 - Personal Information */}
                {step === 2 && (
                  <fieldset>
                    <div className="form-card">
                      <h2 className="fs-title">Personal Information:</h2>
                      <label className="fieldlabels">Full Name: *</label>
                      <input type="text" name="fullName" placeholder="Full Name" required />
                      <label className="fieldlabels">Date of Birth: *</label>
                      <input type="date" name="dob" required />
                      <label className="fieldlabels">Contact No.: *</label>
                      <input type="tel" name="contactNo" placeholder="Contact No." required />
                      <label className="fieldlabels">Address: *</label>
                      <input type="text" name="address" placeholder="Address" required />
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={nextStep}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                      onClick={prevStep}
                    />
                  </fieldset>
                )}

                {/* Step 3 - Qualifications */}
                {step === 3 && (
                  <fieldset>
                    <div className="form-card">
                      <h2 className="fs-title">Qualifications:</h2>
                      <label className="fieldlabels">Highest Degree: *</label>
                      <input type="text" name="degree" placeholder="Highest Degree" required />
                      <label className="fieldlabels">Specialization: *</label>
                      <input type="text" name="specialization" placeholder="Specialization" required />
                      <label className="fieldlabels">Certifications: </label>
                      <input type="text" name="certifications" placeholder="Certifications (if any)" />
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={nextStep}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                      onClick={prevStep}
                    />
                  </fieldset>
                )}

                {/* Step 4 - Experience */}
                {step === 4 && (
                  <fieldset>
                    <div className="form-card">
                      <h2 className="fs-title">Teaching Experience:</h2>
                      <label className="fieldlabels">Total Years of Experience: *</label>
                      <input type="number" name="experience" placeholder="Years of Experience" required />
                      <label className="fieldlabels">Subjects Taught: *</label>
                      <input type="text" name="subjects" placeholder="Subjects Taught" required />
                      <label className="fieldlabels">Previous Schools or Institutions: *</label>
                      <input type="text" name="institutions" placeholder="Previous Schools/Institutions" required />
                    </div>
                    <input
                      type="button"
                      name="next"
                      className="next action-button"
                      value="Next"
                      onClick={nextStep}
                    />
                    <input
                      type="button"
                      name="previous"
                      className="previous action-button-previous"
                      value="Previous"
                      onClick={prevStep}
                    />
                  </fieldset>
                )}

                {/* Step 5 - Finish */}
                {step === 5 && (
                  <fieldset>
                    <div className="form-card">
                      <h2 className="purple-text text-center">
                        <strong>SUCCESS! You Have Applied Successfully!</strong>
                      </h2>
                      <br />
                      <h5 className="purple-text text-center">
                        Thank you for joining our Virtual School!
                      </h5>
                      <div className="row justify-content-center">
                        <div className="col-3">
                          <img
                            src="https://i.imgur.com/GwStPmg.png"
                            className="fit-image"
                            alt="Success"
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TeacherEnrollForm;
