import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const KitDetailsDisplay = () => {
  const { id } = useParams();
  const [kitDetails, setKitDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const fetchKitDetails = async () => {
      try {
        const response = await axiosInstance(`/kits/getkitsdetails/${id}`); // Replace with your API endpoint
        setKitDetails(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchKitDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <Navbar />

      <section className="about">
        <div className="container mt-3 mb-3">
        <div className="section-title text-center">
            <h1>{kitDetails.name}</h1>
           
            </div>

            <div class="container mb-4">
  <div class="row mb-4">
    
  <div class="col-5 text-center mt-4">
  <img 
    src={kitDetails.image} 
    alt="IoT Solutions in Action" 
    class="img-fluid rounded-circle border" 
    style={{ maxHeight: '500px', width: 'auto' }} 
  />
</div>

    <div class="col-7 text-justify">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <div style={{ width: '40px', height: '2px', backgroundColor: 'yellow', marginRight: '10px' }}></div>
        <h3 className="text-primary mb-4 mt-4" style={{ margin: 0 }}>
        Your First Step into the World of Robots!
        </h3>
        <div style={{ width: '40px', height: '2px', backgroundColor: 'yellow', marginLeft: '10px' }}></div>
      </div>

      <p class='fs-6'>{kitDetails.details?.description}
          </p>
      
      <div className="mb-4">
        <a href="/contact" class="btn btn-primary">Contact Us</a>
      </div>
    </div>

  </div>
</div>
     
<div className="card mb-4 shadow mx-4">
                        <div className="card-header bg-primary text-white">
                          <h5>Features</h5>
                        </div>
                        <div className="card-body">

         
            <div
              dangerouslySetInnerHTML={{
                __html: kitDetails.details?.features
              }}
            />
            </div>
            </div>
           

            <div className="card mb-4 shadow mx-4">
                        <div className="card-header bg-primary text-white">
                          <h5>Components</h5>
                        </div>
                        <div className="card-body">
            <div
              dangerouslySetInnerHTML={{
                __html: kitDetails.details?.components
              }}
            />
                 </div>
            </div>
           

            {kitDetails.pdf && (
              <div>
                <h3>PDFs</h3>
                {kitDetails.pdf.split(",").map((pdfUrl, index) => (
                  <a
                    key={index}
                    href={pdfUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View PDF {index + 1}
                  </a>
                ))}
              </div>
            )}
          </div>
       
      </section>

      <Footer />
    </div>
  );
};

export default KitDetailsDisplay;
