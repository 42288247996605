import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";

const VirtualClassroom = () => {
  return (
    <div>
        <Navbar/>
        <div class="container mb-4 mt-4">
    <div class="row inbox">
     
        <div class="col-md-3">
            <div class="panel panel-default">
                <div class="panel-body inbox-menu">
                
                    <a href="add-class.html" class="btn btn-primary btn-block">+ Add New Class</a>
                    
             
                    <ul>
                        <li>
                            <a href="#"><i class="fa fa-book"></i> My Classes</a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-calendar"></i> Schedule</a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-user"></i> Students</a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-tasks"></i> Assignments</a>
                        </li>
                        <li>
                            <a href="#"><i class="fa fa-comments"></i> Discussions</a>
                        </li>
                        <li class="title">
                            Categories
                        </li>
                        <li>
                            <a href="#">Mathematics <span class="label label-danger">2</span></a>
                        </li>
                        <li>
                            <a href="#">Science <span class="label label-info">4</span></a>
                        </li>
                        <li>
                            <a href="#">History <span class="label label-success">1</span></a>
                        </li>
                        <li>
                            <a href="#">Arts <span class="label label-warning">3</span></a>
                        </li>
                        <li>
                            <a href="#">Programming <span class="label label-primary">5</span></a>
                        </li>
                    </ul>
                </div>    
            </div>
            
         
               
        </div>
        
      
        <div class="col-md-9">
            <div class="panel panel-default">
                <div class="panel-body m-4">
                    <h3>Ongoing Classes</h3>

                 
                    <ul class="messages-list">
                        <li class="unread">
                            <a href="class-details.html">
                                <div class="header">
                                    <span class="action"><i class="fa fa-circle"></i></span> 
                                    <span class="from">Mathematics - Algebra</span>
                                    <span class="date">Today, 10:00 AM - 11:30 AM</span>
                                </div>
                                <div class="description">
                                    <strong>Instructor:</strong> Mr. John Doe | <strong>Classroom:</strong> Room 12
                                    <br/><strong>Description:</strong> This session focuses on linear equations and graph plotting.
                                    <br/><strong>Resources:</strong> <a href="#">Lesson Plan</a>, <a href="#">Worksheets</a>, <a href="#">Recordings</a>
                                </div>
                            </a>
                        </li>

                        <li>
                            <a href="class-details.html">
                                <div class="header">
                                    <span class="action"><i class="fa fa-circle"></i></span> 
                                    <span class="from">Science - Physics</span>
                                    <span class="date">Today, 12:00 PM - 1:30 PM</span>
                                </div>
                                <div class="description">
                                    <strong>Instructor:</strong> Dr. Jane Smith | <strong>Classroom:</strong> Room 5
                                    <br/><strong>Description:</strong> Understanding Newton's Laws of Motion with practical examples.
                                    <br/><strong>Resources:</strong> <a href="#">Slides</a>, <a href="#">Experiments</a>, <a href="#">Assignments</a>
                                </div>
                            </a>
                        </li>

                        <li class="unread">
                            <a href="class-details.html">
                                <div class="header">
                                    <span class="action"><i class="fa fa-circle"></i></span> 
                                    <span class="from">History - Ancient Civilizations</span>
                                    <span class="date">Today, 2:00 PM - 3:30 PM</span>
                                </div>
                                <div class="description">
                                    <strong>Instructor:</strong> Ms. Clara Lee | <strong>Classroom:</strong> Room 8
                                    <br/><strong>Description:</strong> Exploring the cultures of Mesopotamia and Egypt.
                                    <br/><strong>Resources:</strong> <a href="#">Readings</a>, <a href="#">Discussion Topics</a>, <a href="#">Quizzes</a>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>    
            </div>    
        </div>
    </div>
</div>
    <Footer/>
    </div>
  );
};

export default VirtualClassroom;