import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const CourseDetails = () => {
  const { id } = useParams(); // Get course ID from route parameters
  const [blog, setBlog] = useState(null);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axiosInstance.get(`/getcoursedetails/${id}`);
        setBlog(response.data);
        console.log(response.data); // Log the response to see the data
      } catch (error) {
        console.error('Error fetching course details:', error);
        setError('Failed to load course details. Please try again later.');
      }
    };

    fetchCourseDetails();
  }, [id]);

  if (error) return <p>{error}</p>;
  if (!blog) return <p>Loading...</p>;

  // Safely access classSchedule and classes to avoid undefined errors
  const { classSchedule = [], classes = [] } = blog.details || {};

  return (
    <div>
      <Navbar />

        
          <section className="about">
            <div className="container mt-3 mb-3">
              <div className="section-title text-center">
                <h2 className='m-4'>{blog.details.title}</h2>
                <p className='m-4'>{blog.details.overview}</p>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <h4 className='mx-4'>Class Schedule</h4>
                  <ul className="mx-4">
  {blog.details.classSchedule.split('\n').map((detail, index) => (
    detail.trim() && <li key={index}>{detail.trim()}</li>
  ))}
</ul>



             
                  {classes.length > 0 ? (
                    classes.map((cls, index) => (
                      <div key={index} className="card mb-4 shadow mx-4">
                        <div className="card-header bg-primary text-white">
                          <h5>{cls.title}</h5>
                        </div>
                        <div className="card-body">
                          <p><strong>Objective:</strong> {cls.objective}</p>
                          <h6>Topics:</h6>
                          <ul>
                            {cls.topics.map((topic, idx) => (
                              <li key={idx}>{topic}</li>
                            ))}
                          </ul>
                          <p><strong>Activity:</strong> {cls.activity}</p>
                          {cls.code && (
                            <pre className="bg-light p-3">
                              <code>{cls.code}</code>
                            </pre>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No classes available.</p>
                  )}
                </div>
              </div>
            </div>
          </section>
  
      <Footer />
    </div>
  );
};

export default CourseDetails;
