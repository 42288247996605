import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import SideBar from "../SideBar";

const AddKitForm = () => {
  const [kitName, setKitName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [popupText, setPopupText] = useState('');
  const [pdf, setPdf] = useState(null);
  const [message, setMessage] = useState('');
  const [kits, setKits] = useState([]);
  const [editingKit, setEditingKit] = useState(null);
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

  useEffect(() => {
    const fetchKits = async () => {
      try {
        const response = await axiosInstance.get('/kits/details');
        setKits(response.data);
      } catch (error) {
        console.error("Error fetching kits:", error);
      }
    };
    fetchKits();
  }, []);

  console.log(kits)

  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setPdf(file);
    } else {
      alert('Please select a valid PDF file.');
    }
  };

  const [details, setDetails] = useState({
    name: "",
    type: "",
    description: "",
    components: "",
    features: "",
  });


  const handleDetailsChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', kitName);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('price', price);
    formData.append('image', image);
    formData.append('popupText', popupText);
    formData.append('pdf', pdf);

    formData.append("details", JSON.stringify(details));

    try {
      const response = await axiosInstance.post('/kits/fill', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage(response.data.message);
      resetForm();
      refreshKits();
    } catch (error) {
      setMessage('Error adding kit: ' + error.message);
    }
  };

  const resetForm = () => {
    setKitName('');
    setDescription('');
    setCategory('');
    setPrice('');
    setImage(null);
    setPopupText('');
    setPdf(null);
  };

  const refreshKits = async () => {
    const response = await axiosInstance.get('/kits/details');
    setKits(response.data);
  };

  const handleEdit = (kit) => {
    setEditingKit(kit);
    setKitName(kit.name);
    setDescription(kit.description);
    setCategory(kit.category);
    setPrice(kit.price);
    setPopupText(kit.popupText);
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append('name', kitName);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('price', price);
    formData.append('popupText', popupText);
    if (image) formData.append('image', image);
    if (pdf) formData.append('pdf', pdf);

    try {
      await axiosInstance.put(`/kits/update/${editingKit._id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage('Kit updated successfully.');
      resetForm();
      setEditingKit(null);
      refreshKits();
    } catch (error) {
      setMessage('Error updating kit: ' + error.message);
    }
  };

  const handleDelete = async (kitId) => {
    try {
      await axiosInstance.delete(`/kits/delete/${kitId}`);
      setMessage('Kit deleted successfully.');
      refreshKits();
    } catch (error) {
      setMessage('Error deleting kit: ' + error.message);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const [expandedKitId, setExpandedKitId] = useState(null);
  const [kitDetails, setKitDetails] = useState(null); // State to store fetched details
  
  const handleToggleDetails = async (kit) => {
    if (expandedKitId === kit._id) {
      // If the same kit is clicked again, collapse the details
      setExpandedKitId(null);
      setKitDetails(null); // Clear details when collapsing
    } else {
      // Expand the clicked kit
      setExpandedKitId(kit._id); 
      setKitDetails(null); // Clear any previously fetched details
      
      try {
        // Fetch the details of the kit using the details ID
        const response = await axiosInstance.get(`/kits/kit-details/${kit.details}`);
        setKitDetails(response.data); // Set the fetched details in state
      } catch (error) {
        console.error("Error fetching kit details:", error);
        setKitDetails(null); // Optionally set details to null on error to display a fallback
      }
    }
  };
  
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [kitsDetails, setKitsDetails] = useState(null);


  const handleEditKit = (kit) => {
    console.log('Edit button clicked for kit:', kit);
    setKitsDetails(kit); // Load selected kit into state for editing
    setIsModalOpen(true); // Open modal
    console.log('Modal state:', isModalOpen);
  };

  const handleDetailChange = (field, value) => {
    setKitDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value, // Update specific field
    }));
  };


  const handleUpdateDetails = async () => {
    try {
      const response = await axiosInstance.put(`/kits/kitsdetailsupdate/${kitDetails._id}`, kitDetails);
  
      if (response.status !== 200) {
        throw new Error('Failed to update kit');
      }
  
      const updatedKit = response.data.updatedKit;
      console.log('Updated Kit:', updatedKit);
  
      // Update local state to reflect changes
      const updatedKits = kits.map((kit) =>
        kit._id === kitDetails._id ? updatedKit : kit
      );
      setKits(updatedKits); // Update the kits state
  
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      console.error('Error updating kit:', error.message);
    }
  };
  
  


  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header" id="appContent">
      <div className="app-main">

        <SideBar />
        <div className="col mt-4">
          <div className="row">
            <div className="col-md-12">
              <div className="main-card card">
                <div className="card-body">
                  <h2>Add New Kit</h2>
                  {message && <div className="alert alert-info">{message}</div>}
                  <form onSubmit={handleSubmit}>
                    {/* Form Fields */}
                    <div className="mb-3">
                      <label htmlFor="kitName" className="form-label">
                        Kit Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="kitName"
                        value={kitName}
                        onChange={(e) => setKitName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows="3"
                        required
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="category" className="form-label">
                        Category
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="price" className="form-label">
                        Price
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">
                        Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="image"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="popupText" className="form-label">
                        Popup Text
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="popupText"
                        value={popupText}
                        onChange={(e) => setPopupText(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pdf" className="form-label">PDF</label>
                      <input
                        type="file"
                        className="form-control"
                        id="pdf"
                        name="pdf"
                        accept="application/pdf"
                        onChange={handlePdfChange}
                      />
                    </div>
                    <div className='card m-4 p-4'>
                      <h4>Add Kits Deatils</h4>
                      <label htmlFor="name">Title</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={details.name}
                        className="form-control"
                        onChange={handleDetailsChange}
                        placeholder="Enter the kit name"
                        required
                      />

                      <label htmlFor="type">Type</label>
                      <select
                        id="type"
                        name="type"
                        className="form-control"
                        value={details.type}
                        onChange={handleDetailsChange}
                        required
                      >
                        <option value="">Select type</option>
                        <option value="sensor">Sensor</option>
                        <option value="motor">Motor</option>
                        <option value="board">Board</option>
                        <option value="mechanical">Mechanical</option>
                        <option value="other">Other</option>
                      </select>

                      <label htmlFor="description">Description</label>
                      <textarea
                        id="description"
                        name="description"
                        className="form-control"
                        value={details.description}
                        onChange={handleDetailsChange}
                        placeholder="Enter a brief description of the kit"
                        rows="4"
                        required
                      ></textarea>



                      <label htmlFor="components">Components</label>
                      <textarea
                        id="components"
                        name="components"
                        value={details.components}
                        className="form-control"
                        onChange={handleDetailsChange}
                        placeholder="List the components included in the kit"
                        rows="4"
                        required
                      ></textarea>

                      <label htmlFor="features">Features</label>
                      <textarea
                        id="features"
                        name="features"
                        value={details.features}
                        className="form-control"
                        onChange={handleDetailsChange}
                        placeholder="List the key features of the kit"
                        rows="4"
                        required
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary mt-4">Add Kit</button>
                  </form>

                  {/* Kits Table */}
                  <h3 className="mt-4">Existing Kits</h3>
                  <table className="table">
        <thead>
          <tr>
            <th>Kit Name</th>
            <th>Description</th>
            <th>Category</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {kits.map((kit) => (
            <React.Fragment key={kit._id}>
              <tr>
                            <td>{kit.name}</td>
                            <td>{kit.description.split(' ').slice(0, 15).join(' ')}{kit.description.split(' ').length > 15 ? '...' : ''}</td>
                            <td>{kit.category}</td>
                            <td>{kit.price}</td>
                            <td>
                              <img src={kit.image} alt={kit.name} width="50" height="50" />
                            </td>
                            <td><a href={kit.pdf}>View</a></td>
                            <td>
                              <button className="btn btn-warning" onClick={() => handleEdit(kit)}>Edit</button>
                              <button className="btn btn-danger" onClick={() => handleDelete(kit._id)}>Delete</button>
                              <button className="btn btn-info" onClick={() => handleToggleDetails(kit)}>
                                {expandedKitId === kit._id ? 'Hide Details' : 'View Details'}
                              </button>
                            </td>
                          </tr>


                          {expandedKitId === kit._id && (
  <tr>
    <td colSpan="5">
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th>Features</th>
              <th>Components</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {kitDetails ? (
              <tr>
                <td>{kitDetails.name || "N/A"}</td>
                <td>{kitDetails.description || "N/A"}</td>
                <td>{kitDetails.type || "N/A"}</td>
                <td>{kitDetails.features || "N/A"}</td>
                <td>{kitDetails.components || "N/A"}</td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => handleEditKit(kit)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ) : (
              <tr>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => handleEditKit(kit)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </td>
  </tr>
)}

            </React.Fragment>
          ))}
        </tbody>
      </table>

    
      {isModalOpen && (
          <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
            <h2>Edit Kit Details</h2>
            <div className="modal-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateDetails();
              }}
            >
              <label>
                Name:
                <input
                  type="text"
                  value={kitDetails?.name || ""}
                  onChange={(e) => handleDetailChange("name", e.target.value)}
                />
              </label>
              <label>
                Description:
                <textarea
                  value={kitDetails?.description || ""}
                  onChange={(e) =>
                    handleDetailChange("description", e.target.value)
                  }
                />
              </label>
              <label>
                Type:
                <input
                  type="text"
                  value={kitDetails?.type || ""}
                  onChange={(e) => handleDetailChange("type", e.target.value)}
                />
              </label>
              <label>
                Features:
                <textarea
                  value={kitDetails?.features || ""}
                  onChange={(e) =>
                    handleDetailChange("features", e.target.value)
                  }
                />
              </label>
              <label>
                Components:
                <textarea
                  value={kitDetails?.components || ""}
                  onChange={(e) =>
                    handleDetailChange("components", e.target.value)
                  }
                />
              </label>
              <button type="submit" className="btn btn-success">
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </form>
            </div>
          </div>
          </div>
        </div>
      )}

                  {editingKit && (
                    <div className="modal show d-block" tabIndex="-1">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Edit Kit</h5>
                            <button type="button" className="btn-close" onClick={() => setEditingKit(null)}></button>
                          </div>
                          <div className="modal-body">
                            <div className="mb-3">
                              <label htmlFor="editKitName" className="form-label">Kit Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="editKitName"
                                value={kitName}
                                onChange={(e) => setKitName(e.target.value)}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="editDescription" className="form-label">Description</label>
                              <textarea
                                className="form-control"
                                id="editDescription"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows="3"
                              ></textarea>
                            </div>
                            <div className="mb-3">
                              <label htmlFor="editCategory" className="form-label">Category</label>
                              <input
                                type="text"
                                className="form-control"
                                id="editCategory"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="editPrice" className="form-label">Price</label>
                              <input
                                type="number"
                                className="form-control"
                                id="editPrice"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label htmlFor="editPopupText" className="form-label">Popup Text</label>
                              <input
                                type="text"
                                className="form-control"
                                id="editPopupText"
                                value={popupText}
                                onChange={(e) => setPopupText(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="image" className="form-label">Image</label>
                            <input type="file" className="form-control" id="image" onChange={handleImageChange} />
                          </div>

                          <div className="mb-3">
                            <label htmlFor="pdf" className="form-label">PDF</label>
                            <input type="file" className="form-control" id="pdf" accept="application/pdf" onChange={handlePdfChange} />
                          </div>
                          <div className="modal-footer">
                            <button className="btn btn-secondary" onClick={() => setEditingKit(null)}>Close</button>
                            <button className="btn btn-primary" onClick={handleUpdate}>Save changes</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddKitForm;
